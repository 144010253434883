import React from 'react';
import { graphql } from 'gatsby';
import { connect } from "react-redux";
import get from 'lodash/get';
import Seo from '../components/seo';
import Header from '../components/Header';
import Footer from '../components/footer';
import Layout from '../components/layout';
import loadable from '@loadable/component'

const ArticleDetailBody = loadable(() => import(`../components/BlockLibrary/Healthzone/ArticleDetailBody`));

import Breadcrumbs from '../components/BlockLibrary/Healthzone/Breadcrumbs';
import '../sass/main.scss';

class HzArticleTemplate extends React.Component {
     componentDidMount() {
          const brower = typeof window !== `undefined`;
          const article = get(this.props, 'data.contentfulHzArticle');
          this.props.setInfoArticle(article.infoArticle);

          if (brower) {
               var getOmValue = localStorage.getItem("Petinsurance_OM");
               this.props.setTrackingCode(getOmValue);
               var getPhoneValue = localStorage.getItem("Petinsurance_TrackingPhone");
               this.props.setTrackingPhone(getPhoneValue);
          }
          const trustpilotScript = document.createElement('script');
          trustpilotScript.type = 'text/javascript';
          trustpilotScript.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
          trustpilotScript.async = true
          document.head.appendChild(trustpilotScript)
          trustpilotScript.onload = function () {
                var trustbox = document.getElementsByClassName('trustpilot-widget');
                window.Trustpilot.loadFromElement(trustbox);
          };
     }

     // Get infographic article from parent categories
     getParentInfographicArticle = (parentCategory) => {
          if (parentCategory == null) {
               return null;
          }
          // Get infographic article
          var infographicArticle = parentCategory.hz__article ? parentCategory.hz__article.find(article => {
               return article.isInfographic;
          }) : null;

          if (typeof infographicArticle == 'undefined' || infographicArticle == null) {
               infographicArticle = getParentInfographicArticle(parentCategory.parentCategory);
          }

          return infographicArticle;
     }

     getMoreRelatedArticles = (childArticles, parentCategory) => {
          // There's no parent articles left to get
          if (parentCategory == null) {
               return childArticles;
          }

          // Remove infographic articles
          const parentArticles = parentCategory.hz__article ? parentCategory.hz__article.filter(article => {
               return !article.isInfographic;
          }) : [];

          let relatedArticles = childArticles.concat(orderBy(parentArticles, 'createdAt', 'desc'));

          if (relatedArticles.length < 3) {
               relatedArticles = getMoreRelatedArticles(relatedArticles, parentCategory.parentCategory);
          }

          return relatedArticles;
     }

     render() {
          const article = get(this.props, 'data.contentfulHzArticle');

          const path = get(this.props, '*');
          const seoTitleTag = article.seoTitleTag ? article.seoTitleTag : `${article.breadcrumbValue} | Pet Health Insurance & Tips`;
          const seoMetaDescription = article.seoMetaDescription ? article.seoMetaDescription.seoMetaDescription : article.shortDescription;
          let categoryArticles = [];
          let categoryInfographicArticles = [];

          // Level 1 category is the same category
          for (let i = 0; i < 5; i++) {
               categoryArticles = categoryArticles.concat(get(this.props, `data.level${i + 1}CategoryArticles.edges`));
               categoryInfographicArticles = categoryInfographicArticles.concat(get(this.props, `data.level${i + 1}CategoryInfographicArticle.edges`));
          }

          const relatedArticles = categoryArticles.slice(0, 3); // Recent Articles of article sidebar
          const infographicArticle = categoryInfographicArticles.length > 0 ? categoryInfographicArticles[0].node : null; // Infographic article of article sidebar

          const trackingCodeDefault = get(this.props, 'data.getTrackingCodeDefault');
          const trackingCode = article.trackingCode != null ? article.trackingCode : trackingCodeDefault.trackingCode;

          return (
               <Layout>
                    <Seo
                         pageName={article.omniturePageNameLegacy || article.contentName}
                         specificPhone = {article.specificPhone}
                         trackingCode = {trackingCode}
                         title={seoTitleTag}
                         path={path}
                         canonical={article.seoCanonicalUrl ? article.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={seoMetaDescription}
                         robots={article.robotsDirective ? article.robotsDirective.robotsDirective : ''}
                         image={article.socialImage ? article.socialImage : article.featuredImage}
                         category={article.category}
                         article={article}
                         hasBreadcrumb={true}
                         showAntiFlickerSnippet={article.showAntiFlickerSnippet}
                    />
                    <Header header={article.header}></Header>
                    <main id="main-content" role="main">
                         <Breadcrumbs article={article} category={article.category}></Breadcrumbs>
                         <ArticleDetailBody
                              article={article}
                              relatedArticles={relatedArticles}
                              infographicArticle={infographicArticle}
                         />
                    </main>
                    <Footer footer={article.footer}></Footer>
               </Layout>
          )
     }
}

const mapDispatchToProps = dispatch => {
     return {
          setInfoArticle: (data) => dispatch({ type: `GET_INFO_ARTICLE`, payload: data }),
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone }),
     };
}
const mapStateToProps = (state) => {
     return {
          state,
     };
}

export default connect(mapStateToProps, mapDispatchToProps)(HzArticleTemplate);

export const pageQuery = graphql`
     query HzArticleBySlug($slug: String!,$articleId: String!, $level1CategoryId: String, $level2CategoryId: String, $level3CategoryId: String, $level4CategoryId: String, $level5CategoryId: String) {
          site {
               siteMetadata {
                    title
               }
          }
          getTrackingCodeDefault : contentfulPage(contentful_id: {eq: "wChsWYdrCJ5doI8QdVkBk"}) {
               id
               trackingCode
          }
          contentfulHzArticle(slug: {eq: $slug}) {
               ...ContentfulHzArticleFields
          }
          level1CategoryArticles: allContentfulHzArticle(limit: 3, sort: {fields: createdAt, order: DESC}, filter: {isInfographic: {nin: true}, category: {id: {eq: $level1CategoryId}}, id: {ne: $articleId}}) {
               edges {
                    node {
                         ...ContentfulHzCategoryArticleFields
                    }
               }
          }
          level2CategoryArticles: allContentfulHzArticle(limit: 3, sort: {fields: createdAt, order: DESC}, filter: {isInfographic: {nin: true}, category: {id: {eq: $level2CategoryId}}}) {
               edges {
                    node {
                         ...ContentfulHzCategoryArticleFields
                    }
               }
          }
          level3CategoryArticles: allContentfulHzArticle(limit: 3, sort: {fields: createdAt, order: DESC}, filter: {isInfographic: {nin: true}, category: {id: {eq: $level3CategoryId}}}) {
               edges {
                    node {
                         ...ContentfulHzCategoryArticleFields
                    }
               }
          }
          level4CategoryArticles: allContentfulHzArticle(limit: 3, sort: {fields: createdAt, order: DESC}, filter: {isInfographic: {nin: true}, category: {id: {eq: $level4CategoryId}}}) {
               edges {
                    node {
                         ...ContentfulHzCategoryArticleFields
                    }
               }
          }
          level5CategoryArticles: allContentfulHzArticle(limit: 3, sort: {fields: createdAt, order: DESC}, filter: {isInfographic: {nin: true}, category: {id: {eq: $level5CategoryId}}}) {
               edges {
                    node {
                         ...ContentfulHzCategoryArticleFields
                    }
               }
          }
          level1CategoryInfographicArticle: allContentfulHzArticle(limit: 1, sort: {fields: createdAt, order: DESC}, filter: {isInfographic: {eq: true}, category: {id: {eq: $level1CategoryId}}, id: {ne: $articleId}}) {
               edges {
                    node {
                         ...ContentfulHzCategoryArticleFields
                    }
               }
          }
          level2CategoryInfographicArticle: allContentfulHzArticle(limit: 1, sort: {fields: createdAt, order: DESC}, filter: {isInfographic: {eq: true}, category: {id: {eq: $level2CategoryId}}}) {
               edges {
                    node {
                         ...ContentfulHzCategoryArticleFields
                    }
               }
          }
          level3CategoryInfographicArticle: allContentfulHzArticle(limit: 1, sort: {fields: createdAt, order: DESC}, filter: {isInfographic: {eq: true}, category: {id: {eq: $level3CategoryId}}}) {
               edges {
                    node {
                         ...ContentfulHzCategoryArticleFields
                    }
               }
          }
          level4CategoryInfographicArticle: allContentfulHzArticle(limit: 1, sort: {fields: createdAt, order: DESC}, filter: {isInfographic: {eq: true}, category: {id: {eq: $level4CategoryId}}}) {
               edges {
                    node {
                         ...ContentfulHzCategoryArticleFields
                    }
               }
          }
          level5CategoryInfographicArticle: allContentfulHzArticle(limit: 1, sort: {fields: createdAt, order: DESC}, filter: {isInfographic: {eq: true}, category: {id: {eq: $level5CategoryId}}}) {
               edges {
                    node {
                         ...ContentfulHzCategoryArticleFields
                    }
               }
          }
     }
`
